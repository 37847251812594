.template-background-default {
  background-color: #fff;
} // ok

.template-title-default {
  color: #000000;
}

.template-sectiontitle-default {
  color: #008fd6;
}

.template-sectionsubtitle-default {
  color: #004e75;
}

.template-sectiontext-default {
  color: #152935;
}

.template-messages-default {
  color: #566b76;
}

.template-icon-default {
  color: #004e75 !important;
}
.template-icon-text-default {
  color: #1f292e;
}

.template-subtitle-default {
  color: #1f292e;
}

.template-company-title-default {
  color: #d82829;
}

.template-text-default {
  color: #000;
}

.template-socialbutton-default {
  color: #ffffff;
} // not found in figma

.template-vert-separator-default {
  border-color: #004e75 !important;
}
.template-horiz-separator-default {
  color: #98aab3 !important;
}

.template-footer-background-default {
  background-color: #eef1f3 !important;
}
button.template-footer-background-default:hover {
  background-color: lighten(#eef1f3, 20%) !important;
} 

.template-footer-dtls-background-default {
  background-color: #def0f7 !important;
}
button.template-footer-dtls-background-default:hover {
  background-color:lighten(#def0f7, 10%) !important;
}

.template-footer-icon-default {
  color: #004e75;
}
.template-waste-type-default {
  color: #1f292e;
}
.template-logo-title-default {
  color: #545454;
}

///////////////
///////////////  template blue finished

.template-background-blue {
  background-color: #2e223c;
} // ok

.template-title-blue {
  color: #ffffff;
} 

.template-sectiontitle-blue {
  color: #ff991d;
} 

.template-sectionsubtitle-blue {
  color: #00dcff;
} 

.template-sectiontext-blue {
  color: #ffffff;
} 

.template-messages-blue {
  color: #ffffff;
} 

.template-icon-blue {
  color: #00dcff !important;
} 

.template-icon-text-blue {
  color: #ffffff;
} 

.template-subtitle-blue {
  color: #ffffff;
} 

.template-company-title-blue {
  color: #7a4df0df;
} 

.template-text-blue {
  color: #ffffff;
} 

.template-socialbutton-blue {
  background-color: #ffffff;
} //not found in figma

.template-vert-separator-blue {
  border-color: #ff991d !important;
} 

.template-horiz-separator-blue {
  border-color: #ff991d !important;
} 

.template-footer-background-blue {
  background-color: #675280 !important;
} 
button.template-footer-background-blue:hover {
  background-color: lighten(#675280, 20%) !important;
} 

.template-footer-dtls-background-blue {
  background-color: #4d1f83 !important;
} 
button.template-footer-dtls-background-blue:hover {
  background-color: lighten(#4d1f83, 20%) !important;
} 

.template-footer-icon-blue {
  color: #ffbb00;
} 

.template-waste-type-blue {
  color: #ffbb00;
} 

.template-logo-title-blue {
  color: #ffffff;
} 

///////////
/////////// template red finished

.template-background-red {
  background-color: #171923;
} // ok

.template-title-red {
  color: #cbb2ab;
} 

.template-sectiontitle-red {
  color: #c31331;
} 

.template-sectionsubtitle-red {
  color: #f79e1b;
} 

.template-sectiontext-red {
  color: #cbb2ab;
} 

.template-messages-red {
  color: #cbb2ab;
} 

.template-icon-red {
  color: #f79e1b !important;
} 

.template-icon-text-red {
  color: #cbb2ab;
} 

.template-subtitle-red {
  color: #cbb2ab;
} 

.template-company-title-red {
  color: #c31331;
} 

.template-text-red {
  color: #cbb2ab;
} 

.template-socialbutton-red {
  background-color: #ffffff;
} //not found in figma

.template-vert-separator-red {
  border-color: #f79e1b !important;
} 

.template-horiz-separator-red {
  color: #cbb2ab !important;
} 

.template-footer-background-red {
  background-color: #3a405e !important;
} 
button.template-footer-background-red:hover {
  background-color: lighten(#3a405e, 20%) !important;
} 

.template-footer-dtls-background-red {
  background-color: #576293 !important;
} 
button.template-footer-dtls-background-red:hover {
  background-color: lighten(#576293, 20%) !important;
} 

.template-footer-icon-red {
  color: #ffffff;
} 

.template-waste-type-red {
  color: #c31331;
} 

.template-logo-title-red {
  color: #cbb2ab;
} 

////////////////
//////////////// template orange finished

.template-background-orange {
  background-color: #3c312b;
} // ok

.template-title-orange {
  color: #ffffff;
} 

.template-sectiontitle-orange {
  color: #f7b22c;
} 

.template-sectionsubtitle-orange {
  color: #ac9467;
} 

.template-sectiontext-orange {
  color: #ffffff;
} 

.template-messages-orange {
  color: #ffffff;
} 

.template-icon-orange {
  color: #966f26 !important;
} 

.template-icon-text-orange {
  color: #ffffff;
} 

.template-subtitle-orange {
  color: #ffffff;
} 

.template-company-title-orange {
  color: #f7b22c;
} 

.template-text-orange {
  color: #ffffff;
} 

.template-socialbutton-orange {
  background-color: #ffffff;
} // not found in figma

.template-vert-separator-orange {
  border-color: #ac9467 !important;
} 

.template-horiz-separator-orange {
  color: #98aab3 !important;
} 

.template-footer-background-orange {
  background-color: #d1b488 !important;
} 
button.template-footer-background-orange:hover {
  background-color: lighten(#cb841b, 20%) !important;
} 

.template-footer-dtls-background-orange {
  background-color: #f2b962 !important;
} 
.template-footer-dtls-background-orange:hover {
  background-color: lighten(#cb841b, 10%) !important;
}

.template-footer-icon-orange {
  color: #6f4c0a;
} 

.template-waste-type-orange {
  color: #ac9467;
} 

.template-logo-title-orange {
  color: #f7b22c;
} 

/////////////////
///////////////// template white

.template-background-white {
  background-color: #ffffff;
} // ok

.template-title-white {
  color: #16056b;
} 

.template-sectiontitle-white {
  color: #5696fa;
} 

.template-sectionsubtitle-white {
  color: #16056b;
} 

.template-sectiontext-white {
  color: #16056b;
} 

.template-messages-white {
  color: #184790;
} 

.template-icon-white {
  color: #16056b !important;
} 

.template-icon-text-white {
  color: #1f292e;
} 

.template-subtitle-white {
  color: #16056b;
} 

.template-company-title-white {
  color: #fd9519;
} 

.template-text-white {
  color: #000000;
} 

.template-socialbutton-white {
  background-color: #ffffff;
} // not found in figma

.template-vert-separator-white {
  border-color: #004e75 !important;
} 

.template-horiz-separator-white {
  color: #98aab3 !important;
} 

.template-footer-background-white {
  background-color: #b4d7fe !important;
} 
button.template-footer-background-white:hover {
  background-color: lighten(#b4d7fe, 20%) !important;
} 

.template-footer-dtls-background-white {
  background-color: #5696fa !important;
}
button.template-footer-dtls-background-white:hover {
  background-color: lighten(#5696fa, 20%) !important;
} 

.template-footer-icon-white {
  color: #16056b;
} 

.template-waste-type-white {
  color: #1f292e;
} 

.template-logo-title-white {
  color: #16056b;
} 
