.impressum-container {
  max-width: inherit;
  width: "100%";
  min-height: 20px;
  border-radius: 4px;
  border: 1px solid #004e75;
  display: block;
  padding: 12px;
}

.div-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-family: "Roboto Bold";
  font-weight: 500;
}

.impressum-text {
  padding-top: 20px;
}
.impressum-text p {
  margin: 0;
  padding: 0;
}
