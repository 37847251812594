.block {
  display: grid;
  align-items: center;
  margin-bottom: -5%;
}

.dataBlock {
  margin-bottom: 5%;
  display: flex;
  // width: 300px;
}

.iconStyle {
  padding: 5px;
  font-size: 45px;
}

.titleStyle {
  font-weight: 700;
  font-size: 14px;
}

.descriptionStyle {
  font-size: 14px;
}
.dividerHeaderTitle {
  margin-bottom: 5%;
  text-align: center;
  font-weight: 700;
}
