.mainContainer {
  display: flex;
  justify-content: left;
  margin: 0.5rem 0;
  // border: 1px dotted red;
}
.secondaryContainer {
  display: flex;
  flex-direction: column;
  width: 80%;
  // border: 1px dotted red;
}
.secondaryContainerNoIcon {
  display: flex;
  flex-direction: column;
  width: 100%;
  // border: 1px dotted red;
}
.iconDiv {
  object-fit: cover; /* Maintain the aspect ratio */
  margin: 0.1rem 0.3rem;
}
.iconDiv svg {
  width: 100%;
  height: 35px;
}
.titleDiv {
  // font-size: 3.5vw;
  font-weight: 500;
  font-family: "Roboto Bold";
}
// .descDiv {
// 	font-size: 3.5vw;
// }
