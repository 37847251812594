@font-face {
  font-family: 'ts-icon';
  src:  url('fonts/ts-icon.eot?gfr3ad');
  src:  url('fonts/ts-icon.eot?gfr3ad#iefix') format('embedded-opentype'),
    url('fonts/ts-icon.ttf?gfr3ad') format('truetype'),
    url('fonts/ts-icon.woff?gfr3ad') format('woff'),
    url('fonts/ts-icon.svg?gfr3ad#ts-icon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ts-icon-"], [class*=" ts-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ts-icon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ts-icon-smaltimento:before {
  content: "\e904";
}
.ts-icon-dettagli:before {
  content: "\e905";
}
.ts-icon-valorinutrizionali:before {
  content: "\e906";
}
.ts-icon-prodotto:before {
  content: "\e907";
}
.ts-icon-premi:before {
  content: "\e908";
}
.ts-icon-annata:before {
  content: "\e909";
}
.ts-icon-gradalcolica:before {
  content: "\e90a";
}
.ts-icon-affinamento:before {
  content: "\e900";
}
.ts-icon-temperaturaservizio:before {
  content: "\e901";
}
.ts-icon-tempoaffinamento:before {
  content: "\e902";
}
.ts-icon-vitigni:before {
  content: "\e903";
}
