.sectionTitle {
  // font-size: 4vw;
  font-weight: 700;
  padding-bottom: 2rem;
}
.sectionTitleDesc {
  // font-size: 4vw;
  font-weight: 500;
  display: box;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gasMessage {
  // font-size: 4vw;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.allergensIcon {
  margin-top: 1rem;
}
.allergensIcon img {
  height: 80px;
  object-fit: cover; /* Maintain the aspect ratio */
  border-radius: 90%;
  padding: 5px;
}

.organic-title {
  font-style: italic;
  font-weight: 600;
  color: #9e9e9e;
  width: "100%";
  margin-top: 20px;
  margin-bottom: 25px;
  display: "flex";
  justify-content: "right";
}
