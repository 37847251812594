/* Default table styles */
.valoriTable {
  width: 100%;
}

.valoriTable,
th,
.tdStyle {
  border: 1px solid;
  border-collapse: collapse;
  padding: 5px;
}

.title {
  font-weight: 700;
}

.per100mlCell {
  text-align: center;
}

.perPortionCell {
  text-align: center;
}
