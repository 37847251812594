.consumoResp {
  // font-size: 4vw;
  padding: 5px 0;
  font-weight: 700;
  color: #0a93d7;
}
.messageOnRisk {
  // font-size: 4vw;
  font-weight: 500;
  padding-top: 10px;
}
.consumoRespDesc {
  // font-size: 4vw;
  font-weight: 500;
}
.consumoRespIcon {
  height: 50px;
  object-fit: cover; /* Maintain the aspect ratio */
  margin: 0.3rem 0.3rem;
}

.consumoRespSquareIconWide {
  object-fit: cover; /* Maintain the aspect ratio */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 8px;
}

.responsibleConsumptionContainer {  
  background: #fff;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  border-radius: 10px;
  margin-right: 20px !important;
  margin-left: 20px !important;
  margin-top: 10px !important;
  width: unset !important;
}