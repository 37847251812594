.prodChar {
  display: flex;
  flex-direction: column;
}
.charTable {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;

  display: flex;
  flex-wrap: wrap;
  margin: 0.3rem 1rem;
}
.charTableCell {
  vertical-align: top;
  width: 45%;
}

.charTable .charTableCell:nth-child(odd) {
  border-right: 2px solid;
}

.sectionTitle {
  font-weight: 700;
  padding-bottom: 1rem;
}
@media only screen and (min-width: 900px) {
  .charTable td:first-child {
    width: 50%;
  }
}
@media only screen and (min-width: 1300px) {
  .charTable td:first-child {
    width: 50%;
    padding-left: 10%;
  }
  .charTable td:last-child {
    padding-left: 10%;
  }
}
.iconProdStyle {
  padding: 5px;
  font-size: 35px;
}

.mainImage {
  width: 200px;
  height: 50%;
  margin-top: 10px;
}
.charateristicDiv {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
}
