.toolBarButtonStyle {
  position: relative !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  height: 100%;
  flex: 1 !important;
}

.toolBarButtonStyle:hover {
  background-color: #eef1f3 !important;
}

.toolBarButtonDetailsStyle {
  position: relative !important;
  height: 100%;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  flex: 1 !important;
}

.toolBarButtonDetailsStyle:hover {
  background-color: #def0f7 !important;
}

.iconDescriptionStyle {
  position: absolute;
  font-size: 14px;
  margin-top: 40px;
  white-space: nowrap;
}

.toolBarIconStyle {
  margin-bottom: 15px;
}

.scrollStyle {
  display: "none";
}

.fade-in {
  // position: relative;
  opacity: 0;
  transform: translateY(300px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  overflow: hidden;
  height: 0;
  z-index: -1;
}

@media screen and (max-width: 350px) {
  body {
    margin: 0% -1% !important;
  }
}

.fade-in.active {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  height: auto;
  padding-top: 70px;
}

.firstTabStyle {
  position: relative;
  opacity: 0;
  transform: translateY(-300px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  pointer-events: none;
  overflow: hidden;
  height: 0;
}

.firstTabStyle.active {
  opacity: 1;
  transform: translateY(0);
  pointer-events: auto;
  height: auto;
}
