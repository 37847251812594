.iconStyleAllegati {
  padding: 0px 10px;
}

.titleStyleAllegati {
  font-weight: 700;
  text-decoration: underline;
}
.dividerHeaderTitle {
  margin-bottom: 5%;
  text-align: center;
  font-weight: 700;
}
