.aziendaImageStyle {
  width: 200px;
}

.imageDivStyle {
  display: flex;
  justify-content: center;
}

.textStyle {
  text-align: justify;
  font-weight: 400;
}

.iconDivStyle {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  svg {
    padding: 5px;
    border-radius: 90%;
  }
}
.dividerHeaderTitle {
  margin-bottom: 5%;
  text-align: center;
  font-weight: 700;
}
.messageDescription {
  font-size: 20px;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
}
