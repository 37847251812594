.header {
  width: 100%;
  // border: 1px dotted red;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: larger;
  padding: 5px 0.3rem 5px 1px;
  border-bottom: 1px solid #e7e7e7;
}
.header-left {
  width: 100%;
  padding-left: 10px;
  display: flex !important;
  justify-content: flex-start;
  align-items: center;

  .brand-title {
    font-family: "Roboto Italic";
    font-size: 22px;
    margin: 5px 10px;
  }
}
.header-right {
  width: 100%;
  // border: 1px dotted red;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  margin-right: 5px;
}
@media only screen and (min-width: 600px) {
  .header {
    padding: 5px 0.3rem 5px 1px;
    border-bottom: 1px solid #e7e7e7;
  }
}
.euHeaderFlag {
  height: 40px;
  object-fit: cover; /* Maintain the aspect ratio */
}

.languageInput {
  width: 70%;
  margin: 0 1vw;
  font-family: "Roboto Regular";
}
