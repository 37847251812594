.dividerHeader {
  margin-bottom: 5%;
  text-align: center;
  color: #0a93d7;
  font-weight: 700;
}

@font-face {
  font-family: "Cairo Bold";
  src: url("./fonts/Cairo-Bold.ttf") format("truetype");
  /* Add additional src lines for other font file formats if available */
}
@font-face {
  font-family: "Cairo Regular";
  src: url("./fonts/Cairo-Regular.ttf") format("truetype");
  /* Add additional src lines for other font file formats if available */
}
@font-face {
  font-family: "Roboto Regular";
  src: url("./fonts/Roboto-Regular.ttf") format("truetype");
  /* Add additional src lines for other font file formats if available */
}
@font-face {
  font-family: "Roboto Bold";
  src: url("./fonts/Roboto-Bold.ttf") format("truetype");
  /* Add additional src lines for other font file formats if available */
}
@font-face {
  font-family: "Roboto Italic";
  src: url("./fonts/Roboto-Italic.ttf") format("truetype");
  /* Add additional src lines for other font file formats if available */
}

.Title {
  font-family: "Cairo Bold", Arial, sans-serif;
  font-size: 30px;
  text-align: center;
}
.Subtitle {
  font-family: "Cairo Regular", Arial, sans-serif;
  font-size: 20px;
  text-align: center;
}
.Heading {
  font-family: "Roboto Bold", Arial, sans-serif;
  font-size: 20px;
}
.Subheading {
  font-family: "Roboto Regular", Arial, sans-serif;
  font-size: 15px;
}

body {
  margin: 0% 0%;
  font-family: "Roboto Regular", Arial, sans-serif;
  font-size: 15px;
}

@media only screen and (max-width: 370px) {
  body {
    font-size: 15px;
  }
  .Title {
    font-size: 20px;
  }
  .Subtitle {
    font-size: 15px;
  }
  .Heading {
    font-size: 15px;
  }
  .Subheading {
    font-size: 13px;
  }
}
@media only screen and (min-width: 600px) {
  body {
    margin: 0% 15%;
    font-size: 17px;
  }
  .Title {
    font-size: 35px;
  }
  .Subtitle {
    font-size: 25px;
  }
  .Heading {
    font-size: 25px;
  }
  .Subheading {
    font-size: 20px;
  }
}
@media only screen and (min-width: 900px) {
  body {
    margin: 0% 0%;
    font-size: 23px;
  }
  .Title {
    font-size: 40px;
  }
  .Subtitle {
    font-size: 30px;
  }
  .Heading {
    font-size: 30px;
  }
  .Subheading {
    font-size: 25px;
  }
}
