.generalDiv {
  width: 100%;
  // border: 1px dotted red;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.squareIcon {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  object-fit: cover; /* Maintain the aspect ratio */
  overflow: hidden;
  margin: 0.5rem;
}
.roundIcon {
  width: 50px; /* Adjust as needed */
  height: 50px; /* Adjust as needed */
  // border-radius: 50%;
  object-fit: cover; /* Maintain the aspect ratio */
  overflow: hidden;
  margin: 0.5rem;
}

.dividerStyle {
  padding: 40px 35%;
}
