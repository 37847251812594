.generalInfoMain {
  padding-top: 70px;
}

.mainLabel {
	max-height: 300px;
	// border: 1px dotted red;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	margin-bottom: 30px;
}
.mainLabelImg {
  // border: 1px dotted red;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: cover; /* Maintain the aspect ratio */
  border-radius: 5%;
  margin-bottom: 10px;
  margin-top: 10px;
  object-fit: cover;
}

.typeIcon {
  width: 80px;
  object-fit: cover; /* Maintain the aspect ratio */
  overflow: hidden;
  margin-left: 0.5rem;
}
.winery {
  font-size: 5vw;
  padding: 1rem 0;
  font-weight: 700;
}
.wineDesc {
  // font-size: 4vw;
  padding-top: 30px;
}
.countryFlag {
  height: 50px;
  object-fit: cover; /* Maintain the aspect ratio */
  margin-left: -0.3rem;
}

@media only screen and (max-width: 500px) {
  .generalInfoMain {
    padding-top: 60px;
  }
  .mainLabelImg{
    max-width: 98%;
  }
}

@media only screen and (min-width: 600px) {
  .generalInfoMain {
    padding-top: 60px;
  }
}
@media only screen and (min-width: 900px) {
  .generalInfoMain {
    padding-top: 70px;
  }
}

.iconsDiv {
  justify-content: space-around;
  max-width: 200px;
  margin-right: auto;
  margin-left: auto;
}
