.cardBorderStyle {
  border-radius: 20px;
  border: solid #6bb47f;
}

.typoGraphyStyle {
  padding: 0px 8px !important;
  margin-bottom: 0px !important;
}

.cardContentStyle {
  position: relative;
  display: flex;
  align-items: center;
}

.cardIconStyle {
  width: 50px;
}

.cardStyle {
  padding-bottom: 30px;

  .MuiCardContent-root-CardContent-root:last-child {
    padding-bottom: 0 !important;
  }
}

.titleWasteMessages {
  font-size: 16px;
  margin-bottom: 5px;
}

.titleWasteDisposal {
  @extend .titleWasteMessages;
  font-weight: 700;
  text-decoration: underline;
}

.wasteCountryPictogramContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 0 20px 0;

  .trimanStyle {
    width: 80px;
    align-items: center;
  }
}

.dividerHeaderTitle {
  margin-bottom: 5%;
  text-align: center;
  font-weight: 700;
}
